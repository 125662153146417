<template>
  <div>
    <!--begin::Dashboard-->
    <div class="row">
      <div class="col-md-12 mt-6">
        <v-card elevation="0" class="card card-custom  gutter-b" style="background-size: 100%; background-image: url('/media/svg/shapes/abstract-4.svg');">
          <v-card-title class="text-dark">
            Condiciones para la utilización del SIGEACI
            <!-- <v-icon>flaticon-file-2</v-icon> -->
          </v-card-title>

          

          <v-card-text class="text-h6 text-justify text-dark-50">
            Los usuarios de SIGEACI, aceptan por el sólo hecho de su registro en el sitio, que los datos e 
            información a registrar dentro de los diferentes submódulos SIGEACI es confiable y fehaciente, 
            y que la asignación de usuario, otorga su conformidad para que la información en los diferentes submódulos, 
            formen parte de las estadísticas y reportes que se procesen y publiquen por parte de la Secretaría de Planificación y Programación de la Presidencia, 
            sin que la misma tenga responsabilidad equívoca disponible públicamente para efectos. Los usuarios no podrán realizar actos maliciosos o atentatorios contra el sitio, 
            que de alguna manera pueda dañar, inutilizar, sobrecargar, deteriorar, impedir o limitar la utilización de todas o algunas de las funcionalidades del mismo.
          </v-card-text>

        </v-card>
      </div>
    </div>

    <div class="row">

        <div class="col-md-6 col-sm-6 col-xs-12">
            <div class="card card-custom gutter-b" style="height: 250px; background-color: rgb(102, 50, 89); background-position: calc(100% + 0.5rem) 100%; background-size: 100%; background-image: url('/media/svg/patterns/taieri.svg');">
                <div class="card-body d-flex align-items-center">
                    <div><h3 class="text-white font-weight-bolder line-height-lg mb-5"> Cooperación <br>Norte-Sur </h3>
                    <p class="text-h6 text-justify text-white">Breve descripción de los proyectos registrados en la modalidad indicada</p>
                    <v-btn color="light-blue-502" class="white--text btn btn-success font-weight-bold px-6 py-3" to="/proyectos/cns/administracion">Administrar proyectos</v-btn></div>
                </div>
            </div>
        </div>

        <div class="col-md-6 col-sm-6 col-xs-12">
            <div class="card card-custom gutter-b" style="height: 250px;">
                <div class="card-body d-flex align-items-center">
                    <div><h3 class="text-dark font-weight-bolder line-height-lg mb-5"> Cooperación <br>Sur-Sur </h3>
                    <p class="text-h6 text-justify text-dark-50">Breve descripción de los proyectos registrados en la modalidad indicada</p>
                    
                    <v-btn color="light-blue-502" class="white--text btn btn-success font-weight-bold px-6 py-3" to="/proyectos/cns/administracion">Administrar proyectos</v-btn></div>
                </div>
            </div>
        </div>


        <div class="col-xxl-12 order-0 order-xxl-2">
            <div class="card card-custom card-stretch gutter-b">
                <div class="card-header border-0 py-5">
                    <h3 class="card-title align-items-start flex-column">
                        <span class="card-label font-weight-bolder text-dark">Últimos proyectos registrados</span>
                        <span class="text-muted mt-3 font-weight-bold font-size-sm">Últimos proyectos CNS y CSS registrados para de la institución</span>
                    </h3>
                    <div class="card-toolbar">
                        <v-btn color="light-blue-502" class="white--text btn btn-success font-weight-bold px-6 py-3" to="/proyectos/cns/administracion">Ver CNS</v-btn>
                        <v-btn color="light-blue-502" class="white--text btn btn-success font-weight-bold px-6 py-3 ml-2" to="/proyectos/cns/administracion">Ver CSS</v-btn>
                    </div>
                </div>
                <div class="card-body pt-0 pb-3">
                <div class="tab-content">
                    <div class="table-responsive">
                        <table class="table table-head-custom table-vertical-center table-head-bg table-borderless">
                            <thead>
                                <tr class="text-left">
                                    <th class="pl-7" style="min-width: 250px;"><span class="text-dark-75">Proyecto</span></th>
                                    <th style="min-width: 120px;">Tipo de proyecto</th>
                                    <th style="min-width: 120px;">Fecha de registro</th>
                                    <!-- <th style="min-width: 100px;">Acción</th> -->
                                    <!-- <th style="min-width: 100px;"></th> -->
                                    <!-- <th style="min-width: 100px;">rating</th> -->
                                    <!-- <th style="min-width: 101px;"></th> -->
                                </tr>
                            </thead>
                            <tbody>

                                <tr v-for="proyecto in proyectos" :key="proyecto.id">
                                    <td class="pl-0 pt-8">
                                        <div class="d-flex align-items-center">
                                            <div class="symbol symbol-50 symbol-light mr-4"><span class="symbol-label" style="background-color: transparent !important"><img :src="proyecto.tipoProyecto===`CNS` ? `media/logos/logo-cns.png` :  `media/logos/logo-css.png`" alt="" class="h-75 align-self-end"></span></div>
                                        <div>
                                        <a class="text-dark-75 font-weight-bold text-hover-primary mb-1 font-size-lg">{{proyecto.nombreProyecto}}</a><!-- <span class="text-muted font-weight-bold d-block">HTML, JS, ReactJS</span> --></div>
                                        </div>
                                    </td>
                                    <td>
                                        <span class="text-dark-75 font-weight-bolder d-block font-size-lg">{{proyecto.tipoProyecto}}</span><span class="text-muted font-weight-bold">{{proyecto.tipoProyecto=="CNS" ? `Cooperación Norte-Sur` : `Cooperación Sur-Sur`}}</span>
                                    </td>
                                    
                                    <td class="text-right"><span class="text-dark-75 font-weight-bolder d-block font-size-lg">{{proyecto.fechaCreacion}}</span><span class="text-muted font-weight-bold">In Proccess</span></td>
                                    <!--<td>
                                        <span class="text-dark-75 font-weight-bolder d-block font-size-lg">$520</span>
                                        <span class="text-muted font-weight-bold">Paid</span></td>
                                    <td>
                                        <span class="text-dark-75 font-weight-bolder d-block font-size-lg">Intertico</span><span class="text-muted font-weight-bold">Web, UI/UX Design</span></td>
                                    <td> 
                                        <img src="media/logos/stars.png" alt="image" style="width: 5rem;"><span class="text-muted font-weight-bold d-block">Best Rated</span></td>-->
                                    <!-- td class="pr-0 text-right"><a class="btn btn-light-success font-weight-bolder font-size-sm">Ver proyecto</a></td> -->

                                </tr>
                                <!-- <tr>
                                    <td class="pl-0 pt-8">
                                        <div class="d-flex align-items-center">
                                            <div class="symbol symbol-50 symbol-light mr-4"><span class="symbol-label"><img src="media/logos/logo-cns.png" alt="" class="h-75 align-self-end"></span></div>
                                        <div>
                                        <a class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">Brad Simmons</a><span class="text-muted font-weight-bold d-block">HTML, JS, ReactJS</span></div>
                                        </div>
                                    </td>
                                    <td>
                                        <span class="text-dark-75 font-weight-bolder d-block font-size-lg">CNS</span><span class="text-muted font-weight-bold">Cooperación Norte-Sur</span></td>
                                    <td>
                                    <td><span class="text-dark-75 font-weight-bolder d-block font-size-lg">$8,000,000</span><span class="text-muted font-weight-bold">In Proccess</span></td>
                                    <td>
                                        <span class="text-dark-75 font-weight-bolder d-block font-size-lg">$520</span>
                                        <span class="text-muted font-weight-bold">Paid</span></td>
                                    <td>
                                        <span class="text-dark-75 font-weight-bolder d-block font-size-lg">Intertico</span><span class="text-muted font-weight-bold">Web, UI/UX Design</span></td>
                                    <td>
                                        <img src="media/logos/stars.png" alt="image" style="width: 5rem;"><span class="text-muted font-weight-bold d-block">Best Rated</span></td>
                                    <td class="pr-0 text-right"><a class="btn btn-light-success font-weight-bolder font-size-sm">Ver proyecto</a></td>
                                </tr>
                                <tr>
                                    <td class="pl-0 pt-8">
                                        <div class="d-flex align-items-center">
                                            <div class="symbol symbol-50 symbol-light mr-4"><span class="symbol-label"><img src="media/svg/avatars/018-girl-9.svg" alt="" class="h-75 align-self-end"></span>
                                            </div>
                                            <div><a class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">Jessie Clarcson</a><span class="text-muted font-weight-bold d-block">C#, ASP.NET, MS SQL</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <span class="text-dark-75 font-weight-bolder d-block font-size-lg">CNS</span><span class="text-muted font-weight-bold">Cooperación Norte-Sur</span></td>
                                    <td>
                                    <td>
                                        <span class="text-dark-75 font-weight-bolder d-block font-size-lg">$23,000,000</span><span class="text-muted font-weight-bold">Pending</span>
                                    </td>
                                    <td>
                                        <span class="text-dark-75 font-weight-bolder d-block font-size-lg">$1,600</span><span class="text-muted font-weight-bold">Rejected</span></td>
                                    <td><span class="text-dark-75 font-weight-bolder d-block font-size-lg">Agoda</span><span class="text-muted font-weight-bold">Houses &amp; Hotels</span></td>
                                    <td><img src="media/logos/stars.png" alt="image" style="width: 5rem;"><span class="text-muted font-weight-bold d-block">Above Average</span></td>
                                    <td class="pr-0 text-right"><a class="btn btn-light-success font-weight-bolder font-size-sm">Ver proyecto</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="pl-0 pt-8">
                                        <div class="d-flex align-items-center">
                                            <div class="symbol symbol-50 symbol-light mr-4"><span class="symbol-label"><img src="media/logos/logo-css.png" alt="" class="h-75 align-self-end"></span>
                                            </div>
                                            <div><a class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">Lebron Wayde</a><span class="text-muted font-weight-bold d-block">PHP, Laravel, VueJS</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <span class="text-dark-75 font-weight-bolder d-block font-size-lg">CSS</span><span class="text-muted font-weight-bold">Cooperación Sur-Sur</span></td>
                                    <td>
                                    <td><span class="text-dark-75 font-weight-bolder d-block font-size-lg">$2,600,000</span><span class="text-muted font-weight-bold">Paid</span></td>
                                    <td><span class="text-dark-75 font-weight-bolder d-block font-size-lg">$6,700</span><span class="text-muted font-weight-bold">Paid</span></td>
                                    <td><span class="text-dark-75 font-weight-bolder d-block font-size-lg">RoadGee</span><span class="text-muted font-weight-bold">Transportation</span></td>
                                    <td><img src="media/logos/stars.png" alt="image" style="width: 5rem;"><span class="text-muted font-weight-bold d-block">Best Rated</span></td>
                                    <td class="pr-0 text-right"><a class="btn btn-light-success font-weight-bolder font-size-sm">Ver proyecto</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="pl-0 pt-8">
                                        <div class="d-flex align-items-center">
                                            <div class="symbol symbol-50 symbol-light mr-4"><span class="symbol-label"><img src="media/svg/avatars/047-girl-25.svg" alt="" class="h-75 align-self-end"></span></div>
                                            <div><a class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">Natali Trump</a><span class="text-muted font-weight-bold d-block">Python, PostgreSQL, ReactJS</span></div>
                                        </div>
                                    </td>
                                    <td>
                                        <span class="text-dark-75 font-weight-bolder d-block font-size-lg">CSS</span><span class="text-muted font-weight-bold">Cooperación Sur-Sur</span></td>
                                    <td>
                                    <td><span class="text-dark-75 font-weight-bolder d-block font-size-lg">$2,600,000</span><span class="text-muted font-weight-bold">Paid</span></td>
                                    <td><span class="text-dark-75 font-weight-bolder d-block font-size-lg">$14,000</span><span class="text-muted font-weight-bold">Pending</span></td>
                                    <td><span class="text-dark-75 font-weight-bolder d-block font-size-lg">The Hill</span><span class="text-muted font-weight-bold">Insurance</span></td>
                                    <td><img src="media/logos/stars.png" alt="image" style="width: 5rem;"><span class="text-muted font-weight-bold d-block">Average</span></td>
                                    <td class="pr-0 text-right"><a class="btn btn-light-success font-weight-bolder font-size-sm">Ver proyecto</a></td>
                                </tr> -->
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

        <div class="col-md-6 col-sm-6 col-xs-12">
            <div class="card card-custom gutter-b" style="height: 250px;">
                <div class="card-body d-flex align-items-center">
                    <div>
                        <h3 class="text-dark font-weight-bolder line-height-lg mb-5"> Cooperación <br>Descentralizada </h3>
                        <p class="text-h6 text-justify text-dark-50">Breve descripción de los proyectos registrados en la modalidad indicada</p>
                        <v-btn color="light-blue-502" class="white--text btn btn-success font-weight-bold px-6 py-3" to="/proyectos/cns/administracion">Administrar proyectos</v-btn>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-6 col-sm-6 col-xs-12">
            <div class="card card-custom gutter-b" style="height: 250px;background-size: 100%; background-image: url('/media/svg/shapes/abstract-4.svg');">
                <div class="card-body d-flex align-items-center">
                    <div>
                        <h3 class="text-dark font-weight-bolder line-height-lg mb-5"> Actores locales <br> </h3>
                        <p class="text-h6 text-justify text-dark-50">Breve descripción de los proyectos registrados en la modalidad indicada</p>
                        
                        <v-btn color="light-blue-502" class="white--text btn btn-success font-weight-bold px-6 py-3" to="/proyectos/cns/administracion">Administrar proyectos</v-btn>
                    </div>
                </div>
            </div>
        </div>

        


    </div>
    <div class="row d-none">
      <div class="col-xxl-4">
        <MixedWidget1></MixedWidget1>
      </div>
      <div class="col-xxl-4">
        <ListWidget9></ListWidget9>
      </div>
      <div class="col-xxl-4">
        <StatsWidget7></StatsWidget7>
        <StatsWidget12></StatsWidget12>
      </div>

      <div class="col-xxl-4 order-1 order-xxl-1">
        <ListWidget1></ListWidget1>
      </div>
      <div class="col-xxl-8 order-2 order-xxl-1">
        <AdvancedTableWidget2></AdvancedTableWidget2>
      </div>

      <div class="col-xxl-4 order-1 order-xxl-2">
        <ListWidget3></ListWidget3>
      </div>
      <div class="col-xxl-4 order-1 order-xxl-2">
        <ListWidget4></ListWidget4>
      </div>
      <div class="col-lg-12 col-xxl-4 order-1 order-xxl-2">
        <ListWidget8></ListWidget8>
      </div>

      <div class="col-xxl-4 order-1 order-xxl-2">
        <StatsWidget13></StatsWidget13>
      </div>
      <div class="col-xxl-8 order-1 order-xxl-2">
        <AdvancedTableWidget3></AdvancedTableWidget3>
      </div>
    </div>
    <!--end::Dashboard-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {OBTENER_ITEMS_CATALOGO} from "@/core/services/store/catalogos/catalogointerno.module";
import AdvancedTableWidget2 from "@/view/content/widgets/advance-table/Widget2.vue";
import AdvancedTableWidget3 from "@/view/content/widgets/advance-table/Widget3.vue";
import MixedWidget1 from "@/view/content/widgets/mixed/Widget1.vue";
import ListWidget1 from "@/view/content/widgets/list/Widget1.vue";
import ListWidget3 from "@/view/content/widgets/list/Widget3.vue";
import ListWidget4 from "@/view/content/widgets/list/Widget4.vue";
import ListWidget8 from "@/view/content/widgets/list/Widget8.vue";
import ListWidget9 from "@/view/content/widgets/list/Widget9.vue";
import StatsWidget7 from "@/view/content/widgets/stats/Widget7.vue";
import StatsWidget12 from "@/view/content/widgets/stats/Widget12.vue";
import StatsWidget13 from "@/view/content/widgets/stats/Widget13.vue";

export default {
  name: "dashboard",
  components: {
    AdvancedTableWidget2,
    AdvancedTableWidget3,
    MixedWidget1,
    ListWidget1,
    ListWidget3,
    ListWidget4,
    ListWidget8,
    ListWidget9,
    StatsWidget7,
    StatsWidget12,
    StatsWidget13
  },

  data() {
        return { 
        proyectos: [],
    }
  },

  
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
  },
  methods: {
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    },

    async obtenerUltimosCNSCSS(){
        //Obtener los tipos de fuente cooperante
        
        this.proyectos = [];

        await this.$store
            .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'ProyectosCNS/CNSCSS/1'})
            .then(res => {
            if (res.status === 200) {
                this.proyectos = res.data;
            }
            })
            .catch(() => {
                this.proyectos = [];
            });
            
    }
  },

  created(){
    this.obtenerUltimosCNSCSS();
  },
};
</script>
